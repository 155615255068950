<template>
  <div>
    <custom-btn
      id="custom-auth-btn"
      ref="imageBtn"
      color="primary"
      class="ma-1"
      v-bind="$attrs"
      @click="open = true"
    >
      {{ label }}
    </custom-btn>
    <v-dialog
      v-model="open"
      max-width="800px"
      height="500px"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title>{{ item.type }}</v-toolbar-title>
          <v-spacer />
          <v-icon @click="open = false">
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-form v-model="open">
          <v-card-text class="py-5">
            <custom-loading
              v-if="loading"
              type="image"
            />
            <div class="d-flex justify-center align-center">
              <v-img
                v-show="loaded"
                :src="imageUrl"
                :max-height="$vuetify.breakpoint.xs ? '' : '500px'"
                :max-width="$vuetify.breakpoint.xs ? '' : '800px'"
                contain
                @load="onImageLoad"
                @error="onError"
              />
            </div>
            <v-divider v-if="showBtn" />
          </v-card-text>
          <v-card-actions v-if="showBtn">
            <v-container>
              <v-row class="align-center">
                <v-col
                  cols="12"
                  sm="10"
                  class="py-0 px-5"
                >
                  <custom-upload-field
                    ref="customUpload"
                    v-model="file"
                    show-size
                    label="File input"
                    accept=".png,.jpeg,.jpg"
                    @change="(e) => (selectedImage = e)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="mt-1 py-0 px-5 text-center"
                >
                  <custom-btn
                    id="upload"
                    color="primary"
                    :disabled="!isfilled || loading"
                    @click="onUpload"
                  >
                    Upload
                  </custom-btn>
                </v-col>
              </v-row>
              <!-- <v-row class="text-center">
                <v-col class="error--text">
                  <strong v-if="uploadField">Note: Picture size of car should be 120px * 150px</strong>
                </v-col>
              </v-row> -->
            </v-container>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomBtn from '@/shared/components/CustomBtn.vue';
import axios from 'axios';
import CustomUploadField from '@/shared/components/CustomUploadField.vue';
import CustomLoading from '@/shared/components/CustomLoading.vue';
export default {
    name: 'CustomAuthBtn',
    components: {
        CustomBtn,
        CustomUploadField,
        CustomLoading,
    },
    props: {
        label: {
            type: String,
            default: 'Submit',
        },
        item: {
            type: Object,
            default: () => {},
        },
        showBtn: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            open: false,
            file: null,
            selectedImage: null,
            imageUrl: null,
            loading: false,
            loaded: false,
            isfilled: false,
        };
    },
    watch: {
        async open(newValue) {
            if (newValue) {
                this.loaded = false;
                this.loading = true;
                setImmediate(() => {
                    this.imageUrl = this.item.imageUrl;
                });
            } else {
                setImmediate(() => {
                    this.imageUrl = '';
                    this.loading = false;
                    this.loaded = false;
                });
            }
        },
        async selectedImage(newValue) {
            if (newValue) {
                this.isfilled = true;
            } else {
                if (this.selectedImage === null) {
                    setImmediate(() => {
                        this.isfilled = false;
                    });
                }
            }
        },
    },
    methods: {
        async onUpload() {
            this.loading = true;
            this.imageUrl = '';
            this.loaded = false;
            const formData = new FormData();
            formData.append('image', this.selectedImage);
            const response = await axios.post(
                `/cars/update-image/${this.item.id}`,
                formData,
                { params: { awsObjectKey: this.item.awsObjectKey } }
            );
            this.$emit('onUpload');
            this.file = null;
            this.selectedImage = null;
            this.imageUrl = response.data.imageUrl;
            this.isfilled = false;
        },
        onImageLoad() {
            this.loading = false;
            this.loaded = true;
        },
        onError() {
            this.imageUrl = `${this.s3Url}/no-image.jpg`;
        },
    },
};
</script>
