<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
    >
      <v-card
        rounded="lg"
        min-height="268"
      >
        <custom-loading type="staticPage" />
      </v-card>
    </v-col>
  </v-row>
</template>
    
<script>
import CustomLoading from '@/shared/components/CustomLoading.vue';
export default {
    name: 'ResetPasswordLoader',
    components: {
        CustomLoading,
    },
};
</script>