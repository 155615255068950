<template>
  <div>
    <v-card
      elevation="4"
      class="mb-4"
    >
      <v-card-title> Configurations </v-card-title>
    </v-card>
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel
        v-for="(item, index) in config"
        :key="index"
      >
        <v-expansion-panel-header>
          {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <generic-form
            :fields-config="item.config"
            :data="configurations[item.field]"
            :btns="{ show: ['submit'], submitLabel: 'Update' }"
            @onSubmit="(payload) => updateConfig(payload, item.field)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import GenericForm from '@/shared/forms/GenericForm.vue';
import { Messages } from '@/enums';
import { Configs } from '@/enums/field-configurations/configurations.js';

export default {
    name: 'ConFigs',
    components: {
        GenericForm,
    },
    data() {
        return {
            panel: [0],
        };
    },
    computed: {
        ...mapState(['configurations']),
        config(){
            return Configs.filter((config)=>this.hasPermission(config.permission));
        },
    },
    methods: {
        ...mapActions(['setConfig']),
        updateConfig(payload, key) {
            this.configurations[key] = payload;
            this.setConfig(this.configurations);
            this.$toast.success(Messages.success.configUpdatedSuccessfully);
        },
    },
};
</script>
