<template>
  <div>
    <v-card
      elevation="4"
      class="mb-4"
    >
      <v-card-title> Change Email </v-card-title>
    </v-card>
    <v-card class="px-5">
      <v-form
        ref="changeEmailForm"
        v-model="valid"
      >
        <v-container>
          <v-row class="mt-1">
            <v-col
              :sm="6"
              cols="12"
              class="py-0"
            >
              <custom-field
                id="change-email-field"
                v-model="email"
                :required="true"
                label="Email"
                type="email"
                clearable
              />
            </v-col>
            <v-col
              :sm="6"
              cols="12"
              :class="$vuetify.breakpoint.xs ? 'py-0' : 'py-8'"
            >
              <custom-btn
                id="reset-password-btn"
                color="primary"
                :class="$vuetify.breakpoint.xs ? 'float-right' : ''"
                @click="onValidate"
              >
                Submit
              </custom-btn>
              <custom-auth-btn
                id="custom-auth-modal-btn"
                ref="authBtn"
                color="primary"
                :class="$vuetify.breakpoint.xs ? 'float-right' : ''"
                hidden
                @click="onClick()"
              >
                Submit
              </custom-auth-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col />
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import CustomField from '@/shared/components/CustomField.vue';
import CustomAuthBtn from '@/shared/components/CustomAuthBtn.vue';
import CustomBtn from '@/shared/components/CustomBtn.vue';
import UserService from '@/services/user';
import { Messages, RoutesConfig } from '@/enums';
import { mapState } from 'vuex';

export default {
    name: 'ChangeEmail',
    components: {
        CustomField,
        CustomAuthBtn,
        CustomBtn,
    },
    props: {
        showOldPasswordField: {
            type: Boolean,
            default: true,
        },
        btnLabel: {
            type: String,
            default: 'Update',
        },
        isVerticle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valid: false,
            email: '',
            userId: '',
        };
    },
    computed: {
        ...mapState(['user']),
    },
    created() {
        this.userId = this.$route?.params?.userId;
    },
    methods: {
        async onClick() {
            if (!this.user?.isAdmin) {
                await UserService.changeEmail({ email: this.email });
                this.$toast.success(Messages.success.mailSent);
            } else {
                await UserService.changeEmailByAdmin(this.userId, {
                    email: this.email,
                });
                this.$toast.success(
                    `${this.user.fullName}'s email has been updated successfully!`
                );
            }
        },
        onValidate() {
            if (this.$refs.changeEmailForm.validate()) {
                this.$refs.authBtn.$refs.customBtn.$el.click();
            }
        },
    },
};
</script>
