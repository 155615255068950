<template>
  <v-card class="car-calculation">
    <v-container class="pa-5">
      <v-row>
        <v-col cols="12">
          <h3>
            Reservation Summary:
            <v-chip
              v-if="status"
              class="float-right"
              :class="$vuetify.breakpoint.mdAndDown ? 'mt-2' : ''"
              :color="getStatusColor(status)"
            >
              {{ titleCase(status) }}
            </v-chip>
          </h3>
        </v-col>
        <v-col
          v-if="cars.length > 1 || isRequiredCars"
          class="pt-0"
          cols="12"
        >
          <h4 class="info--text">
            Multiple Vehicles have been selected!
          </h4>
        </v-col>
        <v-col v-if="!cars.length">
          <h4>No car selected yet!</h4>
        </v-col>
        <v-col v-else>
          <v-row justify="center">
            <v-col cols="12">
              <strong>
                <span> {{ roundTrip ? "Round Trip" : "" }} Miles:</span>
                <span class="error--text">
                  {{ miles }}
                </span>
              </strong>
            </v-col>
            <v-col
              v-for="(car, index) in cars"
              :key="index"
              :md="cars.length > 1 ? 6 : 8"
            >
              <v-card
                v-if="car.requiredCars"
                elevation="5"
                :color="!$vuetify.theme.dark ? 'grey lighten-3' : '#424242'"
              >
                <v-img
                  height="100"
                  contain
                  :src="car.imageUrl"
                  :lazy-src="car.compressedImageUrl"
                />
                <v-card-title>
                  {{ car.name }}
                </v-card-title>
                <!-- <v-card-text>
                  <div>
                    <strong>Formula: </strong> Number of Cars * Rate * Miles
                  </div>
                </v-card-text> -->
                <v-divider class="mx-4" />
                <v-card-text>
                  <!-- <div>
                    <strong>Rate/Mile:</strong>
                    ${{ car.minimumRate }}
                  </div> -->
                  <div>
                    <strong>Selected Cars:</strong>
                    {{ car.requiredCars }}
                  </div>
                  <!-- <div v-if="extraMilesAmount">
                    <strong>Amount: </strong>
                    "See Below"
                  </div> -->
                  <div>
                    <strong>Amount {{ roundTrip ? "(RoundTrip)" : "" }}:
                    </strong>
                    <amount-value :amount="carTotalAmount(car)" />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="sumOfTotalCars && (isCreatePage ? true : !user.isDriver)"
          cols="12"
        >
          <v-card
            flat
            max-width="444"
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="8"
                  md="12"
                  sm="12"
                  class="pt-0 pr-0"
                  :class="isCreatePage ? 'pb-2' : 'pb-0'"
                >
                  <v-radio-group
                    v-if="isCreatePage"
                    v-model="isBagsChecked"
                    :error-messages="bagsCheckedErrorMessage"
                    row
                    @change="$emit('onBagsChecked', isBagsChecked)"
                  >
                    <template #label>
                      <div><strong>Checking-in Bag(s) ? <span class="red--text">*</span></strong></div>
                    </template>
                    <template
                      v-for="(possibility, index) in possibilities"
                    >
                      <v-radio
                        :key="index"
                        color="primary"
                        class="my-2"
                        :label="possibility.label"
                        :value="possibility.value"
                      />
                    </template>
                  </v-radio-group>
                  <span v-else>
                    <strong>Checking-in Bag(s) ? </strong>
                    <span v-if="isBagsChecked">
                      Yes <v-icon color="primary">mdi-check-outline</v-icon>
                    </span>
                    <span v-else>
                      No <v-icon color="error">mdi-close-outline</v-icon>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <h2 class="black--text">
                    Calculations
                  </h2>
                </v-col>
                <v-col
                  v-if="roundTrip"
                  cols="12"
                  class="py-1"
                >
                  <strong>Sub Total {{ roundTrip ? "(RoundTrip)" : "" }}:
                  </strong>
                  <amount-value :amount="sumOfTotalCars" />
                </v-col>
                <v-col
                  v-if="isCreatePage && currentStep < 4 && (checkingLocation !== 'toAirport' ? true : roundTrip ? true: false )"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="isMeetAndGreet"
                    :label="`Meet and Greet (${
                      meetAndGreet.amount
                        ? `$${meetAndGreet.amount}`
                        : `$${meetAndGreet.percentage}`
                    })`"
                    color="primary"
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="isCreatePage && currentStep < 4"
                  cols="12"
                  class="pb-0"
                >
                  <!-- <div class="mb-2">
                    Gratuity
                  </div> -->
                  <v-form ref="gratuityForm">
                    <custom-select
                      id="gratuity-select"
                      v-model="gratuity"
                      class="gratuity-select"
                      :required="true"
                      :items="gratuityOptions"
                      label="Gratuity"
                      placeholder="Please select from the list"
                      dense
                      solo
                      outlined
                      flat
                      aria-required="true"
                      @change="$emit('onGratuityChange', { gratuity })"
                      @click="customGratuity = 0"
                    />
                    <custom-field
                      v-if="gratuity === 'custom'"
                      id="custom-gratuity"
                      v-model="customGratuity"
                      class="custom-gratuity mt-2"
                      type="number"
                      label="Custom Gratuity"
                      prefix="$"
                      required
                      @keyup="
                        $emit('onCustomChange', { gratuity, customGratuity })
                      "
                    />
                  </v-form>
                </v-col>
                <v-col
                  v-if="isCreatePage && currentStep < 4"
                  cols="12"
                  class="pt-0"
                >
                  <custom-field
                    id="test"
                    v-model="discountCode"
                    label="Discount Code"
                    :hint="'Testint the hint'"
                  />
                </v-col>
                <v-col
                  v-if="isCreatePage && currentStep < 4"
                  cols="12"
                  class="pt-0"
                >
                  <p
                    v-if="discountConfig.message"
                    class="d-inline"
                  >
                    <span :class="`${discountConfig.color}--text`">
                      {{ discountConfig.message }}
                    </span>
                  </p>
                  <custom-btn
                    id="validate-btn"
                    color="primary"
                    class="float-right"
                    :disabled="!discountCode"
                    :loading="isDiscountCodeLoading"
                    @click="onDiscountCodeApplied"
                  >
                    Apply
                  </custom-btn>
                  <custom-btn
                    v-if="discounts.length"
                    id="clear-btn"
                    color="error"
                    class="float-right mr-1"
                    @click="clearDiscountCode"
                  >
                    clear
                  </custom-btn>
                </v-col>
                <v-col
                  v-if="discounts.length"
                  cols="12"
                  class="py-0"
                >
                  <div
                    v-if="discounts.length === 1"
                    class="py-1"
                  >
                    <strong class="primary--text">
                      <span>{{
                        discounts[0].amount === 0
                          ? `${discounts[0].percentage}%`
                          : ""
                      }}
                        Discount:
                      </span>
                    </strong>
                    <span v-if="discountAppliedInPercentage">
                      <amount-value :amount="discountAppliedInPercentage" />
                    </span>
                    <span v-else>
                      <amount-value :amount="discounts[0].amount" />
                    </span>
                  </div>
                  <!-- <div v-else>
                    <v-divider class="my-2" />
                    <h3 class="text-h10 mb-1 primary--text">
                      Discounts:
                    </h3>
                    <div
                      v-for="(disCode, index) in discounts"
                      :key="index"
                      cols="12"
                      class="py-1"
                    >
                      <div v-if="+disCode.amount">
                        <strong> {{ disCode.code }}: </strong>
                        <amount-value :amount="disCode.amount" />
                      </div>
                      <div v-else>
                        <strong> {{ disCode.code }}: </strong>
                        {{ disCode.percentage }}%
                      </div>
                    </div>
                  </div> -->
                  <div
                    v-if="discountAmount"
                    class="py-1"
                  >
                    <strong> Amount After Discount: </strong>
                    <amount-value :amount="discountAmount" />
                  </div>
                  <v-divider
                    v-if="discounts.length > 1"
                    class="my-2"
                  />
                </v-col>

                <surge-calculation
                  :surges="surges"
                  :return-surges="returnSurges"
                  @surgesCalculation="onChangeSurge"
                />

                <v-col
                  v-if="totalExtraStopCharges"
                  cols="12"
                  class="py-1"
                >
                  <strong>Extra Stop Charges: </strong>
                  <amount-value :amount="totalExtraStopCharges" />
                </v-col>

                <v-col
                  v-if="totalReturnExtraStopCharges"
                  cols="12"
                  class="py-1"
                >
                  <strong>Return Extra Stop Charges: </strong>
                  <amount-value :amount="totalReturnExtraStopCharges" />
                </v-col>

                <v-col
                  v-if="totalGratuity"
                  cols="12"
                  class="py-1"
                >
                  <strong>Gratuity: </strong>
                  <amount-value :amount="totalGratuity" />
                </v-col>

                <v-col
                  v-if="isMeetAndGreet && meetAndGreetAmount"
                  cols="12"
                  class="py-1"
                >
                  <strong> Meet And Greet Charges: </strong>
                  <amount-value :amount="meetAndGreetAmount" />
                </v-col>
                <!-- <v-col
                  v-if="extraMilesAmount && user.isAdmin"
                  cols="12"
                  class="py-1"
                >
                  <strong>Extra Miles Charges: </strong>
                  <amount-value :amount="extraMilesAmount" />
                </v-col> -->
                <v-col
                  v-if="addonsAmount"
                  cols="12"
                  class="py-1"
                >
                  <custom-span :help-text="addonHelpText">
                    <strong> Addons: </strong>
                    <amount-value :amount="addonsAmount" />
                  </custom-span>
                </v-col>
                <v-col
                  cols="12"
                  class="py-1"
                >
                  <div @blur="$emit('totalBill', { totalbill })">
                    <h3>
                      <span class="error--text">
                        Total Bill{{ roundTrip ? " (RoundTrip)" : "" }}: </span><amount-value :amount="totalBill" />
                    </h3>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import SurgeCalculation from '@/components/SurgeCalculation.vue';
import ReservationMixin from '@/mixins/ReservationMixin';
import CustomField from '@/shared/components/CustomField.vue';
import CustomBtn from '@/shared/components/CustomBtn.vue';
import CustomSpan from '@/shared/components/CustomSpan.vue';
import CustomSelect from '@/shared/components/CustomSelect.vue';
import { mapState } from 'vuex';
import ReservationService from '@/services/reservation';
import {
    getItemFromLocalStorage,
    setItemOnLocalStorage,
    removeItemOnLocalStorage,
} from '@/common';

export default {
    components: {
        SurgeCalculation,
        CustomField,
        CustomSelect,
        CustomBtn,
        CustomSpan,
    },
    mixins: [ReservationMixin],
    props: {
        miles: {
            type: String,
            default: '',
        },
        childMiles: {
            type: String,
            default: '',
        },
        parentMiles: {
            type: String,
            default: '',
        },
        status: {
            type: String,
            default: '',
        },
        isCreatePage: {
            type: Boolean,
            default: true,
        },
        checkingLocation: {
            type: String,
            default: '',
        },
        extraMilesAmount: {
            type: Number,
            default: null,
        },
        currentStep: {
            type: Number,
            default: 1,
        },
        payload: {
            type: Object,
            default: () => {},
        },
        surges: {
            type: Array,
            default: () => [],
        },
        cars: {
            type: Array,
            default: () => [],
        },
        returnSurges: {
            type: Array,
            default: () => [],
        },
        extraStops: {
            type: Array,
            default: () => [],
        },
        returnExtraStops: {
            type: Array,
            default: () => [],
        },
        roundTrip: {
            type: Boolean,
            default: false,
        },
        reservationAddons: {
            type: Array,
            default: () => [],
        },
        bagsCheckedErrorMessage: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        discountCode: null,
        isBagsChecked: null,
        discountConfig: {},
        isMeetAndGreet: false,
        isDiscountCodeLoading: false,
        surgeAmount: 0,
        discountAppliedInPercentage: 0,
        surgePercentage: 0,
        discountedAmount: 0,
        discountedPercentage: 0,
        discountedPrice: 0,
        discounts: [],
        gratuity: 0,
        customGratuity: null,
        gratuityOptions: [
            { text: 'No thanks / i\'ll take care of Driver', value: 1 },
            { text: '15%', value: 15 },
            { text: '20%', value: 20 },
            { text: '25%', value: 25 },
            { text: '30%', value: 30 },
            { text: 'Custom', value: 'custom' },
        ],
        possibilities:[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
    }),
    computed: {
        ...mapState(['configurations', 'user']),
        addonHelpText() {
            return `To see more details click on the Addons Tab in the Details section${
                this.$vuetify.breakpoint.xs ? ' above.' : ' on the left.'
            }`;
        },
        sumOfTotalCars() {
            let sum = 0;
            this.cars.forEach((car) => {
                sum = this.carTotalAmount(car) + sum;
            });
            return sum;
        },
        isRequiredCars() {
            return this.cars.some((car) => car.requiredCars > 1);
        },
        totalExtraStopCharges() {
            return (
                this.extraStops.length *
        (this.configurations?.extraStopCharges?.amount || 0)
            );
        },
        totalReturnExtraStopCharges() {
            return (
                this.returnExtraStops.length *
        (this.configurations?.extraStopCharges?.amount || 0)
            );
        },
        totalGratuity() {
            const customGratuity = this.getNumericValue(this.customGratuity);
            let gratuity = this.getNumericValue(this.gratuity);
            gratuity = gratuity === 1 ? 0 : gratuity;
            let totalGratuity = 0;
            if (customGratuity) {
                return customGratuity;
            }
            totalGratuity = this.amountCalculated * (gratuity / 100);

            return +Number(totalGratuity).toFixed(2);
        },
        amountCalculated() {
            return this.discountAmount || this.sumOfTotalCars;
        },
        surgesCalculation() {
            if (this.surgeAmount) {
                return this.surgeAmount;
            }
            let percentage = this.amountCalculated * (this.surgePercentage / 100);
            return this.getNumericValue(percentage);
        },
        totalBill() {
            const total = this.totalBillExceptAddons + this.addonsAmount;
            return +Number(total).toFixed(2);
        },
        totalBillExceptAddons() {
            let totalBill =
        this.amountCalculated +
        this.totalExtraStopCharges +
        this.totalReturnExtraStopCharges +
        this.meetAndGreetAmount +
        this.totalGratuity +
        this.surgesCalculation;

            // totalBill += this.extraMilesAmount || 0;
            this.onCalculationChange(totalBill);

            return +Number(totalBill).toFixed(2);
        },
        discountAmount() {
            this.updatingValue();
            this.discountAmountPercentageSum;
            this.discountedAmountAndPercentage();
            return +Number(this.discountedPrice).toFixed(2);
        },
        discountAmountPercentageSum() {
            return this.discounts?.forEach((obj) => {
                if (+obj.amount) {
                    this.discountedAmount = this.discountedAmount + +obj.amount;
                } else {
                    this.discountedPercentage =
            this.discountedPercentage + +obj.percentage;
                }
            });
        },
        meetAndGreetAmount() {
            if (this.isMeetAndGreet) {
                const amount = this.getNumericValue(this.meetAndGreet?.amount);
                const percentage = this.getNumericValue(this.meetAndGreet?.percentage);
                if (amount) {
                    return amount;
                }
                const value = this.sumOfTotalCars * (percentage / 100);
                return +Number(value).toFixed(2);
            }
            return 0;
        },
        meetAndGreet() {
            return this.isCreatePage
                ? this.configurations?.meetAndGreet
                : this.payload.meetAndGreet;
        },
        addonsAmount() {
            let amount = 0;
            let percentage = 0;

            this.reservationAddons.forEach((addon) => {
                let addonAmount = +addon.amount;
                let addonPercentage = +addon.percentage;
                if (addonAmount) {
                    amount += addonAmount;
                } else if (addonPercentage) {
                    percentage += addonPercentage;
                }
            });
            percentage = +this.totalBillExceptAddons * (percentage / 100);

            amount = amount + percentage;
            return amount;
        },
    },
    watch: {
        url(newValue, oldValue) {
            if (oldValue !== newValue) {
                this.onLoad();
            }
        },
    },
    created() {
        if (this.isCreatePage) {
            this.setDataFromLocalStorage();
        } else {
            this.discounts = this.payload?.discounts || [];
        }
        this.gratuity = this.gratuity || +this.payload?.gratuity || 0;
        this.customGratuity =
      this.customGratuity || this.payload?.customGratuity || null;
        this.isMeetAndGreet =
      this.isMeetAndGreet || this.payload?.meetAndGreet?.amount ? true : false;
        this.isBagsChecked = this.isBagsChecked || this.payload?.isBagsChecked || 0;
    },
    methods: {
        clearDiscountCode() {
            this.discountCode = '';
            this.discounts = [];
            this.updatingValue();
            this.discountedPrice = 0;
            this.discountConfig = {};
        },
        onCalculationChange(totalBill) {
            const payload = JSON.parse(getItemFromLocalStorage('payload'));

            if ((payload || {}).step2) {
                payload.step2.customGratuity = this.customGratuity;
                payload.step2.discounts = this.discounts || [];
                payload.step2.isMeetAndGreet = this.isMeetAndGreet;
                payload.step2.selectedGratuity = this.gratuity;
                payload.step2.isBagsChecked = this.isBagsChecked;
                setItemOnLocalStorage('payload', JSON.stringify(payload));
            }

            this.$emit('onChange', {
                totalBill: totalBill,
            });
        },
        setDataFromLocalStorage() {
            const payload = JSON.parse(getItemFromLocalStorage('payload'));

            if ((payload || {}).step2) {
                this.discounts = payload.step2.discounts || [];
                this.gratuity = payload.step2.selectedGratuity || 0;
                this.customGratuity = payload.step2.customGratuity || 0;
                this.isMeetAndGreet = payload.step2.isMeetAndGreet;
                this.isBagsChecked = payload.step2.isBagsChecked;
                this.discountCode = ((this.discounts || [])[0] || {}).code;
                if (this.discountCode) {
                    this.discountConfig = {
                        message: 'Discount Applied!',
                        color: 'primary',
                    };
                }
            }
        },
        onChangeSurge(e) {
            this.surgeAmount = e.amount;
            this.surgePercentage = e.percentage;
        },
        async onDiscountCodeApplied() {
            this.discounts = [];
            try {
                this.isDiscountCodeLoading = true;
                const response = await ReservationService.verifyDiscountCode(
                    this.discountCode
                );
                this.discounts[0] = response?.data;
                this.discounts = JSON.parse(JSON.stringify(this.discounts));
                this.discountConfig = {};
                this.discountConfig = {
                    message: 'Discount Applied!',
                    color: 'primary',
                };
                this.isActiveDiscountCode = true;
            } catch (e) {
                this.discountConfig = {
                    message: 'Invalid Discount Code',
                    color: 'error',
                };
            } finally {
                this.isDiscountCodeLoading = false;
            }
        },
        updatingValue() {
            this.discountedAmount = 0;
            this.discountedPercentage = 0;
        },
        discountedAmountAndPercentage() {
            if (this.discountedAmount != 0) {
                this.discountedPrice = this.sumOfTotalCars - this.discountedAmount;
            }
            if (this.discountedPercentage) {
                let amount = this.sumOfTotalCars;
                this.discountAppliedInPercentage = (
                    amount *
          (this.discountedPercentage / 100)
                ).toFixed(2);
                this.discountedPrice = amount - this.discountAppliedInPercentage;
            }
        },
        carTotalAmount(car) {
            const calculations = {
                car,
                miles: this.miles,
                parentMiles: this.parentMiles,
                childMiles: this.childMiles,
                extraMilesAmount: this.extraMilesAmount,
                roundTrip: this.roundTrip,
                isCreatePage: true,
            };
            return this.reservationCarCalculation(calculations);
        },
    },
};
</script>
<style lang="scss">
.car-calculation {
  .v-text-field__details {
    display: none !important;
  }

  .custom-gratuity {
    .v-text-field__details {
      display: block !important;
    }
  }

  .gratuity-select {
    .v-text-field__details {
      display: block !important;
    }
  }

  .break-word {
    word-break: break-word;
  }
}
</style>
