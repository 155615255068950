
<template>
  <v-dialog
    v-model="display"
    persistent
    :width="dialogWidth"
  >
    <template #activator="{ on }">
      <custom-field
        :id="`text-field-props-${id}`"
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :required="required"
        :placeholder="placeholder"
        :value="formattedDatetime"
        prepend-inner-icon="mdi-calendar-clock"
        readonly
        v-on="on"
      >
        <template #progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            />
          </slot>
        </template>
      </custom-field>
    </template>
    <v-card>
      <v-card-text
        class="px-0 py-0"
      >
        <v-tabs
          v-model="activeTab"
          fixed-tabs
        >
          <v-tab key="calendaTab">
            <v-icon
              class="mr-1"
              small
            >
              mdi-calendar
            </v-icon>
            Date
          </v-tab>
          <v-tab
            key="timerTab"
            :disabled="dateSelected"
          >
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock
            </v-icon>
            Time
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              full-width
              @input="showTimePicker"
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timerPicker"
              v-model="time"
              class="v-time-picker-custom"
              v-bind="timePickerProps"
              full-width
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <custom-btn
          :id="cancelBtnId"
          @click.native="clearHandler"
        >
          {{ clearText }}
        </custom-btn>
        <custom-btn
          :id="okBtnId"
          color="primary"
          @click="okHandler"
        >
          {{ okText }}
        </custom-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomField from './CustomField.vue';
import CustomBtn from './CustomBtn.vue';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'Clear';
const DEFAULT_OK_TEXT = 'Ok';
export default {
    name: 'CustomDateTimePicker',
    components: {
        CustomBtn,
        CustomField,
    },
    model: {
        prop: 'datetime',
        event: 'input',
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        datetime: {
            type: [Date, String],
            default: null,
        },
        disabled: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        dialogWidth: {
            type: Number,
            default: DEFAULT_DIALOG_WIDTH,
        },
        clearText: {
            type: String,
            default: DEFAULT_CLEAR_TEXT,
        },
        okText: {
            type: String,
            default: DEFAULT_OK_TEXT,
        },
        textFieldProps: {
            type: Object,
            default: () => {},
        },
        datePickerProps: {
            type: Object,
            default: () => {},
        },
        timePickerProps: {
            type: Object,
            default: () => {},
        },
        clearDateTime: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            display: false,
            activeTab: 0,
            date: DEFAULT_DATE,
            time: DEFAULT_TIME,
            errorMessages: [],
        };
    },
    computed: {
        cancelBtnId() {
            return `${this.id}_cancelBtn`;
        },
        okBtnId() {
            return `${this.id}_okBtn`;
        },
        textFieldId() {
            return `${this.id}_textFieldId`;
        },
        formattedDatetime() {
            if (!this.selectedDatetime) {
                return null;
            }
            const [hourStr, minStr] = this.time.split(':');
            let hour = parseInt(hourStr, 10);
            const amOrPm = hour < 12 ? 'a.m' : 'p.m';
            if (hour > 12) {
                hour %= 12;
            } else if (hour === 0) {
                hour = 12;
            }
            return `${this.date} ${hour}:${minStr} ${amOrPm}`;
        },
        selectedDatetime() {
            if (this.date && this.time) {
                let datetimeString = `${this.date}T${this.time}`;
                if (this.time.length === 5) {
                    datetimeString += ':00';
                }

                const result = `${datetimeString}Z`;
                return result;
            }
            return null;
        },
        dateSelected() {
            return !this.date;
        },
    },
    watch: {
        datetime(newVal) {
            this.init(newVal);
            this.runRules(newVal);
        },
        clearDateTime(newValue) {
            if (newValue) {
                this.clearHandler();
                this.$emit('updateClearDateTimeFlag');
            }
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init(newVal) {
            if (!this.datetime) {
                if (newVal === null) {
                    this.date = DEFAULT_DATE;
                    this.time = DEFAULT_TIME;
                }
                return;
            }
            let initDateTime;
            if (this.datetime instanceof Date) {
                initDateTime = this.datetime;
            } else if (
                typeof this.datetime === 'string' ||
        this.datetime instanceof String
            ) {
                initDateTime = new Date(Date.parse(this.datetime));
            }
            const [dateStr, timeStr] = initDateTime.toISOString().split('T');
            this.date = dateStr;
            this.time = timeStr.replace('.000Z', '');
        },
        okHandler() {
            this.resetPicker();
            this.$emit('input', this.selectedDatetime);
            this.$emit('change', this.selectedDatetime);
        },
        clearHandler() {
            this.resetPicker();
            this.date = DEFAULT_DATE;
            this.time = DEFAULT_TIME;
            this.$emit('input', null);
            this.$emit('change', null);
        },
        resetPicker() {
            this.display = false;
            this.activeTab = 0;
            if (this.$refs.timerPicker) {
                this.$refs.timerPicker.selectingHour = true;
            }
        },
        showTimePicker() {
            this.activeTab = 1;
        },
        runRules(newVal) {
            this.errorMessages = [];
            this.rules.forEach((rule) => {
                const result = rule(newVal);
                if (typeof result === 'string') {
                    this.errorMessages.push(result);
                }
            });
        },
    },
};
</script>
<style lang="scss">
.v-time-picker-custom {
  .v-picker__title {
    height: 84px;
    padding-top: 10px;
  }
}
.v-picker__title__btn--active {
  cursor: pointer;
}
</style>
