<template>
  <custom-autocomplete
    id="value"
    v-model="inputVal"
    :items="results"
    :item-text="itemText"
    return-object
    v-bind="$attrs"
    v-on="$listeners"
    @change="(payload) => $emit('select', payload)"
  />
</template>
  
<script>
import CustomAutocomplete from '@/shared/components/CustomAutocomplete.vue';
import UserService from '@/services/user.js';

export default {
    name: 'UserSearchField',
    components: {
        CustomAutocomplete,
    },
    props: {
        value: {
            type: Object,
            default: () => { },
        },
        searchOn: {
            type: String,
            default: 'user',
        },
        itemText: {
            type: String,
            default: 'email',
        }
    },
    data() {
        return {
            results: [],
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('value', val);
            },
        },
    },
    created(){
        this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            let method = 'getUsers';

            switch (this.searchOn) {
            case 'getAffiliates':
                method = 'getAffiliates';
            }

            const response = await UserService[method]();
            this.results = response.dataItems;
        },
    },
};
</script>
  