import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import { get } from 'lodash';
import {
    getItemFromLocalStorage,
    setItemOnLocalStorage,
    clearDataFromLocalStorage,
} from '@/common';
import { hasPermission } from '@/mixins/MainMixin';
import { PERMISSIONS } from '@/enums';

const getters = {};

const state = () => ({
    user: {},
    configurations: {},
    pages: [],
    cars: [],
});

const mutations = {
    SET_USER(state, payload) {
        state.user = payload.user;
        setItemOnLocalStorage('token', payload.token);
        setItemOnLocalStorage('user', JSON.stringify(payload.user));
        setItemOnLocalStorage('isAdmin', payload.user.isAdmin);
    },
    SET_ME(state, payload) {
        state.user = payload;
    },
    SET_CONFIGURATION(state, payload) {
        state.configurations = payload;
    },
    SET_PAGES(state, payload) {
        state.pages = payload;
    },
    SET_CARS(state, payload) {
        state.cars = payload.map((car) => ({
            ...car,
            requiredCars: 1,
            isSelected: false,
        }));
    },
    SET_STATUSES(state, payload = []) {
        setItemOnLocalStorage('statuses', JSON.stringify(payload));
    },
    SET_STATIC_PAGES(state, payload = []) {
        setItemOnLocalStorage('staticPages', JSON.stringify(payload));
    },
    SET_ADDONS(state, payload = []) {
        setItemOnLocalStorage('addons', JSON.stringify(payload));
    },
    SET_DRIVERS(state, payload = []) {
        setItemOnLocalStorage('drivers', JSON.stringify(payload));
    },
    SET_SIGNED_IN_USER(state, payload) {
        state.user = payload.user;
    },
    SET_SIGNED_OUT_USER(state) {
        state.user = {};
        clearDataFromLocalStorage();
    },
};

const actions = {
    async login({ commit, dispatch }, payload) {
        const response = await axios.post('/login', payload);
        commit('SET_USER', response.data);
        await dispatch('fetchStatuses');
        if (hasPermission(PERMISSIONS.staticPageEditorView)) {
            dispatch('fetchStaticPages');
        }
        return response.data;
    },
    async signup({ commit }, payload) {
        const response = await axios.post('/signup', payload);
        commit('SET_USER', response.data);
    },
    async getMe({ commit }) {
        const user = getItemFromLocalStorage('user');
        if (user) {
            commit('SET_ME', JSON.parse(user));
        } else if (getItemFromLocalStorage('token')) {
            const response = await axios.get('/me');
            commit('SET_ME', response.data.user);
        }
    },
    async getConfig({ commit }) {
        const response = await axios.get('/configurations');
        commit('SET_CONFIGURATION', response.data);
    },
    async setConfig({ commit }, payload) {
        axios.put('/configurations/1', payload);
    },
    async getPages({ commit }) {
        const response = await axios.get('/static-pages/available');
        commit('SET_PAGES', response.dataItems);
    },
    async createPage({commit, state}, payload) {
        const response = await axios.post('/static-pages', payload);
        return response;
    },
    signOut({ commit }) {
        commit('SET_SIGNED_OUT_USER', {});
    },
    async fetchStaticPages({ commit }) {
        const response = await axios.get('/static-pages');
        commit('SET_STATIC_PAGES', response.dataItems);
    },
    async fetchCars({ commit }) {
        const response = await axios.get('/cars/available');
        commit('SET_CARS', response.dataItems);
    },
    async fetchLoggedInUser({ commit }) {
        const response = await axios.get('/me');
        commit('SET_SIGNED_IN_USER', response.data);
    },
    async fetchStatuses({ commit }) {
        const response = await axios.get('/statuses');
        commit('SET_STATUSES', response.dataItems);
    },
    async fetchAddons({ commit }) {
        const response = await axios.get('/addons');
        commit('SET_ADDONS', response.dataItems);
    },
    async fetchDrivers({ commit }) {
        const response = await axios.get('/users?role=Driver');
        commit('SET_DRIVERS', response.dataItems);
    },
    async reIntializeUser({ commit }) {
        const response = await axios.get('/me');
        response.data.token = getItemFromLocalStorage('token');
        commit('SET_USER', response.data);
    },
};

Vue.use(Vuex);
export default new Vuex.Store({
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
});
