import { MDI_ICONS } from '../mdi-icons';
import { PERMISSIONS } from '../permissions';
const DefaultConfig = {
    sm: 6,
    cols: 12,
    md: 6,
    required: true,
};

export const Configs = [
    {
        title: 'Meet And Greet',
        field: 'meetAndGreet',
        permission: PERMISSIONS.configurationsMeetAndGreet,
        config: [
            {
                ...DefaultConfig,
                id: 'amount',
                vModel: 'amount',
                type: 'number',
                label: 'Amount',
                optionalIf: 'percentage',
                requiredError: 'Amount or percentage is required',
                'prepend-inner-icon': MDI_ICONS.dollar,
            },
            {
                ...DefaultConfig,
                id: 'percentage',
                vModel: 'percentage',
                type: 'number',
                label: 'Percentage',
                optionalIf: 'amount',
                requiredError: 'Percentage or amount is required',
                'prepend-inner-icon': MDI_ICONS.percent,
            },
        ],
    },
    {
        title: 'Max Miles Surge',
        field: 'maxMilesSurge',
        permission: PERMISSIONS.configurationsMaxMilesSurge,
        config: [
            {
                ...DefaultConfig,
                id: 'maxMiles',
                vModel: 'maxMiles',
                type: 'number',
                label: 'Max Miles',
            },
            {
                ...DefaultConfig,
                id: 'amount',
                vModel: 'amount',
                type: 'number',
                label: 'Amount',
                'prepend-inner-icon': MDI_ICONS.dollar,
            },
        ],
    },
    {
        title: 'Extra Stop',
        field: 'extraStopCharges',
        permission: PERMISSIONS.configurationsExtraStop,
        config: [
            {
                ...DefaultConfig,
                id: 'amount',
                vModel: 'amount',
                type: 'number',
                label: 'Amount',
                sm: 4,
                md: 4,
                'prepend-inner-icon': MDI_ICONS.dollar,
            },
        ],
    },
    {
        title: 'Home',
        field: 'home',
        permission: PERMISSIONS.configurationsHome,
        config: [
            {
                ...DefaultConfig,
                id: 'questionare',
                vModel: 'questionare',
                name: 'questionare',
                label: 'Questionare',
                placeholder: 'Enter Question ',
            },
            {
                ...DefaultConfig,
                id: 'coreValue',
                vModel: 'coreValue',
                name: 'coreValue',
                label: 'Core Value',
                placeholder: 'Enter your core value',
            },

            {
                ...DefaultConfig,
                id: 'contactNumber',
                vModel: 'contactNumber',
                name: 'contactNumber',
                label: ' Contact Number',
                placeholder: 'Enter contact number',
                type: 'phoneNumber',
            },
            {
                ...DefaultConfig,
                id: 'workingHours',
                vModel: 'workingHours',
                name: 'workingHours',
                label: 'Working Hours',
                placeholder: 'Enter working hours',
            },
            {
                ...DefaultConfig,
                id: 'welcomeDescription',
                vModel: 'welcomeDescription',
                name: 'welcomeDescription',
                label: 'Welcome Description',
                placeholder: 'Enter description',
                'auto-grow': true,
                type: 'ck-editor',
            },
            {
                ...DefaultConfig,
                id: 'customerSatisfactionDesciption',
                vModel: 'customerSatisfactionDescription',
                name: 'customerSatisfactionDesciption',
                label: ' Customer Satisfaction Description',
                placeholder: 'Enter customer satisfaction description',
                'auto-grow': true,
                type: 'ck-editor',
            },
            {
                ...DefaultConfig,
                id: 'servicesWeOfferDesciption',
                vModel: 'serviceWeOfferDescription',
                name: 'servicesWeOfferDesciption',
                label: ' Service We Offer Description',
                placeholder: 'Enter Service We Offer description',
                'auto-grow': true,
                type: 'ck-editor',
            },
            {
                ...DefaultConfig,
                id: 'PaymentDesciption',
                vModel: 'paymentDescription',
                name: 'PaymentDesciption',
                label: ' Payment Description',
                placeholder: 'Enter Payment description',
                'auto-grow': true,
                type: 'ck-editor',
            },
        ],
    },
    {
        title: 'Messages',
        field: 'messages',
        permission: PERMISSIONS.configurationsMessages,
        config: [
            {
                ...DefaultConfig,
                id: 'reservationAlert',
                vModel: 'reservationAlert',
                label: 'Reservation Alert',
                type: 'ck-editor',
                md: 12,
                sm: 12,
            },
        ],
    },
];
