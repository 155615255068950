<template>
  <v-dialog
    v-model="open"
    transition="dialog-bottom-transition"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-toolbar
        :color="color"
        dark
      >
        <v-toolbar-title>Are you sure?</v-toolbar-title>
        <v-spacer />
        <v-icon @click="onClick(false)">
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-card-text class="py-5">
        <div
          class="break-word text-subtitle-1"
          v-html="cleanHtml(title)"
        />
        <v-spacer />
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <custom-btn
          id="confirmation-modal_cancel-btn"
          class="mb-2"
          @click="onClick(false)"
        >
          Cancel
        </custom-btn>
        <custom-btn
          id="confirmation-modal_confirm-btn"
          class="mb-2"
          :color="color"
          :disabled="disableConfirmButton"
          @click="onClick(true)"
        >
          Confirm
        </custom-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomBtn from '@/shared/components/CustomBtn.vue';
export default {
    name: 'ConfirmationModal',
    components: {
        CustomBtn,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Are you sure you want to perform this action?',
        },
        color: {
            type: String,
            default: 'primary',
        },
        disableConfirmButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        onClick(isConfirmed) {
            if (isConfirmed) {
                this.$emit('confirm');
            } else {
                this.$emit('cancel');
            }
        },
    },
};
</script>
