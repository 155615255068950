import { render, staticRenderFns } from "./ReservationUpdatePageLoader.vue?vue&type=template&id=0a9a2f46&"
import script from "./ReservationUpdatePageLoader.vue?vue&type=script&lang=js&"
export * from "./ReservationUpdatePageLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports