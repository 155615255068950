<template>
  <v-container
    class="py-0"
    :class="inputVal.length && !$vuetify.breakpoint.smAndDown ? 'px-15' : 'px-0'"
  >
    <v-row>
      <v-col
        v-for="(stop, extraStopIndex) in inputVal"
        :key="extraStopIndex"
        cols="12"
        class="py-0 align-center"
      >
        <google-place-dropdown
          v-model="stop.value"
          label="Extra Stop"
          placeholder="Enter Extra Stop Location"
          no-data-text="Type to search location..."
          required
          class="mr-3"
          prepend-inner-icon="mdi-map-marker"
          append-outer-icon="mdi-delete"
          @click:append-outer="onRemovingStop(extraStopIndex)"
        />
      </v-col>
      <v-col
        cols="12"
        class="mb-5"
        :class="$vuetify.breakpoint.smAndDown ? 'pt-0' : 'py-0'"
      >
        <custom-btn
          id="add-extra-stop"
          color="primary"
         
          small
          :disabled="isAddExtraStopBtnDisabled"
          @click="onAddingExtraStop()"
        >
          Add Extra stop
        </custom-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import CustomBtn from '@/shared/components/CustomBtn.vue';
import GooglePlaceDropdown from '@/shared/components/GooglePlaceDropdown.vue';
export default {
    name: 'ExtraStops',
    components: {
        CustomBtn,
        GooglePlaceDropdown,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        extraStops: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('value', val);
            },
        },
        isAddExtraStopBtnDisabled() {
            return !this.inputVal.every((i) => i.value);
        },
    },
    methods: {
        onRemovingStop(index) {
            this.inputVal.splice(index, 1);
        },
        onAddingExtraStop() {
            this.inputVal.push({ value: null });
        },
    },
};
</script>
