<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndDown ? '' : 'px-12'"
  >
    <user-profile-loader v-if="isLoading" />
    <error-page
      v-else-if="error"
      :error="error"
    />
    <v-row v-else>
      <v-col
        cols="12"
        sm="4"
        md="3"
        lg="2"
        xl="2"
      >
        <v-card class="mx-auto">
          <div class="mx-auto text-center align-center">
            <v-avatar
              color="primary"
              size="106"
              class="mt-5"
            >
              <span class="white--text">
                <h1>{{ userDetails.initials ||user.initials }}</h1>
              </span>
            </v-avatar>
          </div>
          <v-card-title class="justify-center text-center">
            <div>Edit Account</div>
          </v-card-title>
          <v-card-text>
            <v-list dense>
              <template v-for="(item, i) in listItems">
                <v-list-item
                  :key="i"
                  :to="item.path"
                  link
                >
                  <v-list-item-title class="text-capitalize">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-icon v-if="item.icon">
                    <v-icon small>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="9"
        lg="10"
        xl="10"
      >
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { RoutesConfig } from '@/enums';
import { mapState } from 'vuex';
import UserService from '@/services/user';
import Service from '@/services/index';
import { PERMISSIONS } from '@/enums';
import ErrorPage from '@/shared/pages/ErrorPage.vue';
import UserProfileLoader from '@/components/CustomLoader/UserProfileLoader.vue';
export default {
    name: 'SettingsPage',
    components: {
        UserProfileLoader,
        ErrorPage,
    },
    data() {
        return {
            RoutesConfig,
            userId: null,
            error: '',
            isLoading: false,
            userDetails: {},
        };
    },
    computed: {
        ...mapState(['user']),
        listItems() {
            const items = [
                {
                    title: 'Profile Info',
                    path: `${this.userId || this.user?.id}/profile-info`,
                    permission: PERMISSIONS.usersProfileInfo,
                },
                {
                    title: 'User Attachments',
                    path: `${this.userId || this.user?.id}/user-attachments`,
                    permission: PERMISSIONS.usersAttachments,
                },
                {
                    title: 'Change Password',
                    path: `${this.userId || this.user?.id}/change-password`,
                    permission: PERMISSIONS.usersChangePassword,
                },
                {
                    title: 'Change Email',
                    path: `${this.userId || this.user?.id}/change-email`,
                    permission: PERMISSIONS.usersChangeEmail,
                },
                {
                    title: 'Jobs',
                    path: `${this.userId || this.user?.id}/user-reservations`,
                    permission: PERMISSIONS.usersJobs,
                    preCondition: (user) => (user.isDriver ? true : false),
                },
            ];

            items.map((item) => {
                this.setSettingsPath(item);
            });
            let list = items
                .filter((i) => (i.permission ? this.hasPermission(i.permission) : true))
                .filter((i) =>
                    i.preCondition ? i.preCondition(this.userDetails || this.user) : true
                );

            return list;
        },
    },
    created() {
        this.userId = this.$route?.params?.userId;
        if (this.userId) {
            this.isLoading = true;
            this.service = new Service('/users');
            this.fetch();
        }
    },
    methods: {
        async fetch() {
            try {
                const response = await this.service.get(this.userId);
                this.userDetails = response.data;
                this.isLoading = false;
            } catch (e) {
                this.error = e;
                this.isLoading = false;
            }
        },
        setSettingsPath(items = []) {
            if (!Array.isArray(items)) {
                items = [items];
            }
            items.forEach((item) => {
                if (item.path) {
                    item.path = `/users/${item.path}`;
                }
            });
        },
    },
};
</script>
<style scoped>
.routerLink {
  text-decoration: none;
}
</style>
