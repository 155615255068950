import { RoutesConfig } from './enums';
import HomePage from './pages/HomePage';
import Configurations from './pages/Configurations';
import SettingsPage from './pages/SettingsPage';
import CrudPage from './pages/CrudPage';
import PageNotFound from './pages/PageNotFound';
import StaticPageEditor from './pages/StaticPageEditor';
import StaticPage from './pages/StaticPage';
import ReservationDetailsPage from './pages/ReservationDetailsPage';
import ReservationCreatePage from './pages/ReservationCreatePage';
import ReservationUpdatePage from './pages/ReservationUpdatePage';
import UserProfile from './pages/UserProfile';
import ForgotPassword from './pages/ForgotPassword.vue';
import ResetPassword from './pages/ResetPassword.vue';
import EmailVerifiedPage from './pages/EmailVerifiedPage.vue';
import RolesPermissionsAssignPage from './pages/RolesPermissionsAssignPage.vue';
import ProfileInfo from './pages/ProfileInfo.vue';
import ChangePasswordPage from './pages/ChangePasswordPage.vue';
import UserAttachments from '@/components/UserAttachments.vue';
import ChangeEmail from '@/shared/forms/ChangeEmail.vue';
import UserReservations from '@/components/UserReservations.vue';

const route = [
    { ...RoutesConfig.default, component: HomePage },
    { ...RoutesConfig.home },
    {
        ...RoutesConfig.userProfile,
        component: UserProfile,
        children: [
            {
                ...RoutesConfig.userProfile.children.profileInfo,
                component: ProfileInfo,
            },
            {
                ...RoutesConfig.userProfile.children.userAttachments,
                component: UserAttachments,
            },
            {
                ...RoutesConfig.userProfile.children.changePassword,
                component: ChangePasswordPage,
            },
            {
                ...RoutesConfig.userProfile.children.changeEmail,
                component: ChangeEmail,
            },
            {
                ...RoutesConfig.userProfile.children.userReservations,
                component: UserReservations,
            },
        ],
    },
    {
        ...RoutesConfig.settings,
        component: SettingsPage,
        children: [
            {
                ...RoutesConfig.settings.children.roles,
                component: RolesPermissionsAssignPage,
            },
            {
                ...RoutesConfig.settings.children.permissions,
                component: RolesPermissionsAssignPage,
            },
            {
                ...RoutesConfig.settings.children.usersWithRoles,
                component: RolesPermissionsAssignPage,
            },
            {
                ...RoutesConfig.settings.children.configurations,
                component: Configurations,
            },
        ],
    },
    //  Crud Routes
    {
        ...RoutesConfig.forgotPassword,
        component: ForgotPassword,
    },
    {
        ...RoutesConfig.resetPassword,
        component: ResetPassword,
    },
    {
        ...RoutesConfig.reservations,
        component: CrudPage,
    },
    {
        ...RoutesConfig.reservationDetails,
        component: ReservationDetailsPage,
    },
    {
        ...RoutesConfig.reservationUpdate,
        component: ReservationUpdatePage,
    },
    {
        ...RoutesConfig.reservationCreate,
        component: ReservationCreatePage,
    },
    {
        ...RoutesConfig.cars,
        component: CrudPage,
    },
    {
        ...RoutesConfig.surges,
        component: CrudPage,
    },
    {
        ...RoutesConfig.discountCodes,
        component: CrudPage,
    },
    {
        ...RoutesConfig.addons,
        component: CrudPage,
    },
    {
        ...RoutesConfig.staticPageEditor,
        component: StaticPageEditor,
    },
    {
        ...RoutesConfig.customers,
        component: CrudPage,
    },
    {
        ...RoutesConfig.drivers,
        component: CrudPage,
    },
    {
        ...RoutesConfig.affiliates,
        component: CrudPage,
    },
    {
        ...RoutesConfig.verifyEmail,
        component: EmailVerifiedPage,
    },
    {
        ...RoutesConfig.staticPages,
        component: StaticPage,
    },
    {
        path: '*',
        component: PageNotFound,
    },
];

export default route;
