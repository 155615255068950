<template>
  <div class="my-2">
    <div
      v-if="showLabel"
      class="mb-2"
    >
      <span>
        <strong> {{ label }} </strong>
        <span
          v-if="required && label"
          style="color: red"
        >*</span>
      </span>
    </div>
    <ckeditor
      :id="id"
      v-model="inputVal"
      :editor="editor"
      :config="editorConfig"
      v-bind="$attrs"
    />
    <p
      v-if="!required && !inputVal"
      transition="scroll-y-transition"
      class="ck-editor-error"
    >
      Content is Required
    </p>
  </div>
</template>

<script>
import ClassicEditor from '@/plugins/ck-editor/ckeditor';
export default {
    name: 'CustomCkEditor',
    props: {
        value: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {},
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>
<style lang="scss">
.ck-editor-error {
  color: red;
  margin-top: 5px;
  position: absolute;
}
</style>
