<template>
  <v-row>
    <v-col
      cols="12"
      sm="4"
      md="3"
      lg="3"
      xl="2"
    >
      <custom-loading type="sidebar" />
    </v-col>
    <v-col
      cols="12"
      sm="8"
      md="9"
      lg="9"
      xl="10"
    >
      <custom-loading type="ck-editor" />
    </v-col>
  </v-row>
</template>

<script>
import CustomLoading from '@/shared/components/CustomLoading.vue';

export default {
  components: {
    CustomLoading
  },
}
</script>

<style>

</style>