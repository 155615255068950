<template>
  <v-menu
    v-model="isMenuOpen"
    bottom
    :close-on-content-click="false"
    left
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-for="(item, i) in headers"
          :key="i"
          multiple
        >
          <v-list-item @click="item.isActive = !item.isActive">
            <template #default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="item.isActive" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <custom-btn
          id="on-save"
          color="primary"
          @click="onSave"
        >
          Save
        </custom-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import CustomBtn from '@/shared/components/CustomBtn.vue';
import { getItemFromLocalStorage, setItemOnLocalStorage } from '@/common';
export default {
    name: 'DataTableSetting',
    components: {
        CustomBtn,
    },
    props: {
        headers: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: 'Record',
        },
    },
    data: () => ({
        isMenuOpen: false,
        item: [],
        selectedHeaders: [],
    }),
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        localStorageKey() {
            return `Headers-For-${this.title}`;
        },
    },
    watch: {
        isMenuOpen(newValue) {
            if (newValue) {
                this.headers.forEach((h) => (h.isActive = this.value.includes(h.text)));
            }
        },
        title() {
            this.setCurrentStepDataFromLocalStorage();
        },
    },
    created() {
        this.setCurrentStepDataFromLocalStorage();
    },
    methods: {
        getSelectedheaders() {
            return this.headers.filter((h) => h.isActive).map((h) => h.text);
        },
        onSave() {
            this.inputVal = this.getSelectedheaders();
            this.isMenuOpen = false;
            this.setSelectedHeaders();
        },
        setSelectedHeaders() {
            setItemOnLocalStorage(
                this.localStorageKey,
                this.getSelectedheaders().join(',')
            );
        },
        setCurrentStepDataFromLocalStorage() {
            let localStorageHeaders = getItemFromLocalStorage(this.localStorageKey);
            localStorageHeaders = (localStorageHeaders || '')
                .split(',')
                .filter((i) => i);

            this.inputVal = localStorageHeaders.length
                ? this.headers
                    .filter((h) => localStorageHeaders.includes(h.text))
                    .map((h) => h.text)
                : this.headers.map((h) => h.text);
        },
    },
};
</script>
