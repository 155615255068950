<template>
  <v-app :class="$vuetify.theme.dark ? 'dark-mode' : 'light-mode'">
    <custom-app-bar />
    <v-main
      ref="mainContainer"
      class="main-container"
      :class="[$vuetify.theme.dark ? '' : 'grey lighten-3']"
    >
      <router-view />
      <app-footer v-if="!user.isAdmin" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import CustomAppBar from '@/shared/components/CustomAppBar.vue';
import AppFooter from '@/components/AppFooter.vue';
export default {
    name: 'App',
    components: {
        CustomAppBar,
        AppFooter,
    },
    computed: {
        ...mapState(['user']),
    },
};
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-data-table__wrapper::-webkit-scrollbar {
  height: 7px;
}

.v-btn,
.v-tab {
  text-transform: capitalize !important;
}

.main-container {
  padding: 65px 0 48px 0 !important;
  height: 100vh !important;
  .v-main__wrap {
    height: calc(100vh - 65px);
    overflow-y: scroll;
    > .container {
      min-height: calc(100vh - 115px) !important;
    }
  }
}

.break-word {
  word-break: break-word;
}

.w-100 {
  width: 100% !important;
}

.postion-relative {
  position: relative;
}

.v-chip {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 0%);
  padding: 15px;
  width: 100px;
  justify-content: center;
}

$dark-mode-autofill-text-color: #1e1e1e;
$light-mode-autofill-text-color: rgba(0, 0, 0, 0.87);

// .theme--light.v-sheet,
// .theme--light.v-expansion-panels .v-expansion-panel,
// .theme--light.v-date-picker-header
//   .v-date-picker-header__value:not(.v-date-picker-header__value--disabled),
//   button:not(:hover):not(:focus) {
//   color: rgba(0, 0, 0, 0.6) !important;
// }

.dark-mode {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $dark-mode-autofill-text-color inset !important;
  }

  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
}

.light-mode {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: $light-mode-autofill-text-color !important;
  }
}
</style>
