export const RESERVATION_STATUSES = {
    created: 'created',
    viewed: 'viewed',
    draft: 'draft',
    duplicate: 'duplicate',
    assigned: 'assigned',
    completed: 'completed',
    cancelled: 'cancelled',
    unpaid: 'unpaid',
};
