<template>
  <v-dialog
    v-model="open"
    max-width="900"
    persistent
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-spacer />
        <v-toolbar-title> Add Addons </v-toolbar-title>
        <v-spacer />
        <v-icon @click="$emit('close')">
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-container class="pa-7">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <strong>Reservation No:</strong>
            <span class="primary--text ml-2">{{ reservationNumber }} </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            :sm="addons.length ? 9 : 12"
          >
            <generic-form
              v-if="open"
              :fields-config="fieldsConfig"
              :data="addon"
              :btns="{ show: ['submit'], submitLabel: 'Add' }"
              @onSubmit="(payload) => $emit('onSubmit', payload)"
            />
          </v-col>
          <v-col
            v-if="addons.length"
            cols="12"
            sm="3"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-10 pb-0"
              >
                <custom-field
                  id="static-page-search-field"
                  v-model="search"
                  color="primary"
                  placeholder="Search"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  @keyup="onChange()"
                  @click:clear="onClear()"
                />
              </v-col>
              <v-col class="pt-0 pa-0">
                <v-list
                  v-if="open"
                  class="v-list"
                  height="250px"
                  overflow-y="auto"
                  scrollable
                  dense
                >
                  <v-subheader>Addons</v-subheader>
                  <v-list-item-group
                    color="primary"
                    @change="onPageChange"
                  >
                    <v-list-item
                      v-for="(item, i) in filteredAddons"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          scrollable
                          v-text="item.name"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty, cloneDeep } from 'lodash';
import CustomField from '@/shared/components/CustomField.vue';
import GenericForm from '@/shared/forms/GenericForm.vue';
import { getItemFromLocalStorage, setItemOnLocalStorage } from '@/common';

export default {
    name: 'AddonsModal',
    components: {
        GenericForm,
        CustomField,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        reservationNumber: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            addons: [],
            addon: {},
            search: '',
            filteredAddons: [],
        };
    },
    computed: {
        fieldsConfig() {
            return [
                {
                    id: 'Name',
                    vModel: 'name',
                    label: 'Name',
                    sm: 12,
                    cols: 12,
                    required: true,
                },
                {
                    id: 'amount',
                    vModel: 'amount',
                    type: 'number',
                    label: 'Amount',
                    requiredError: 'Amount or percentage is required',
                    sm: 6,
                    cols: 12,
                    required: true,
                    optionalIf: 'percentage',
                },
                {
                    id: 'percentage',
                    vModel: 'percentage',
                    type: 'number',
                    label: 'Percentage',
                    requiredError: 'Amount or percentage is required',
                    sm: 6,
                    cols: 12,
                    required: true,
                    optionalIf: 'amount',
                },
                {
                    id: 'comment',
                    type: 'textarea',
                    vModel: 'comments',
                    label: 'Comments',
                    sm: 12,
                    cols: 12,
                    required: true,
                },
            ];
        },
        addonsFromLS() {
            const value = getItemFromLocalStorage('addons');
            if (!value) return [];
            return JSON.parse(value);
        },
    },
    watch: {
        async open(newValue) {
            if (newValue) {
                this.addon = {};
                this.setAddons();
                return newValue;
            }
        },
    },
    methods: {
        async setAddons() {
            this.addons = this.addonsFromLS;
            this.filteredAddons = [...this.addons];
        },
        onPageChange(index) {
            this.addon = cloneDeep(this.addons[index]);
        },
        onChange() {
            if (!this.search) {
                return (this.filteredAddons = [...this.addons]);
            }
            this.filteredAddons = this.addons.filter((addon) =>
                addon.name.toLowerCase().includes(this.search)
            );
        },
        onClear() {
            this.search = '';
            this.filteredAddons = [...this.addons];
        },
    },
};
</script>
<style scoped>
.v-list {
  overflow-y: auto;
}
</style>
