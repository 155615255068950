import Vue from 'vue';
import VueRouter from 'vue-router';
import VuePaycard from 'vue-paycard';
import CkEditor from '@ckeditor/ckeditor5-vue2';
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import DatetimePicker from 'vuetify-datetime-picker';
import PayPal from 'vue-paypal-checkout';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import routes from './routes';
import store from './store';
import { Messages } from './enums';
import axiosInterceptor from './interceptor';
import realTimeService from '../src/services/real-time';
import vueBraintree from 'vue-braintree';
import VueMeta from 'vue-meta';
import PageTitleMixin from './mixins/PageTitleMixin';
import AmountValue from './shared/components/AmountValue.vue';
import { hasPermission } from './mixins/MainMixin';
import MainMixin from './mixins/MainMixin';
import { getItemFromLocalStorage } from '@/common';

Vue.prototype.s3Url = 'https://the-95-star.s3.amazonaws.com';
Vue.prototype.logoUrl = `${Vue.prototype.s3Url}/logo.png`;
Vue.prototype.compressedLogoUrl = `${Vue.prototype.s3Url}/logo-lazy-src.png`;

// Vue Router Documentation https://v3.router.vuejs.org/installation.html#npm
const router = new VueRouter({
    mode: 'history',
    routes,
});
router.beforeEach((to, from, next) => {
    const token = getItemFromLocalStorage('token');
    let user = JSON.parse(getItemFromLocalStorage('user') || '{}');

    if (to.name === 'home') {
        return next();
    }

    if (
        to.matched.every((rec) => {
            const permission = rec.meta.permission;
            if (!permission) return false;
            return !hasPermission(permission);
        })
    ) {
        return next({ name: 'home' });
    }
    next();
});

Vue.use(VueRouter);
Vue.use(PayPal);
Vue.use(CkEditor);
Vue.use(VuePaycard);
Vue.use(DatetimePicker);
Vue.use(vueBraintree);
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true,
});

Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    console.error(err, '<=== Main.js Error Tracking');
    if (err) vm.$toast.error(Messages.error[err] || err);
};

const options = {
    position: 'topCenter',
    timeout: 1000,
};
Vue.use(VueIziToast, options);
Vue.mixin(PageTitleMixin);
Vue.mixin(MainMixin);
Vue.component('AmountValue', AmountValue);

Promise.all([
    store.dispatch('getPages'),
    store.dispatch('getConfig'),
    store.dispatch('fetchCars'),
]).then((res) => {
    new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
});
