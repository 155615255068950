const DOMPurify = require('dompurify');
import { marked } from 'marked';

import { getItemFromLocalStorage } from '@/common';
export const hasPermission = function (permission = '') {

    const user = JSON.parse(getItemFromLocalStorage('user') || '{}');
    return user?.permissions?.includes(permission);
};

export const cleanHtml = function (value = '') {
    value = `${value}`;

    if (!hasHtmlTags(value)) {
        return value;
    }

    return DOMPurify.sanitize(marked(value));
};

function hasHtmlTags(str) {
    const htmlTagsRegex = /<[a-z][\s\S]*>/i;
    return htmlTagsRegex.test(str);
}

export default {
    methods: {
        cleanHtml,
        hasPermission,
    },
};
