<template>
  <v-row>
    <v-col
      cols="12"
      sm="8"
      md="8"
    >
      <v-card class="mx-auto">
        <custom-loading type="reservationDetailPage" />
      </v-card>
      <v-card class="mx-auto mt-4">
        <custom-loading type="reservationDetailPageDataTable" />
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="4"
      md="4"
    >
      <v-card class="mx-auto">
        <custom-loading type="reservationDetailPageRightSideBar" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CustomLoading from '@/shared/components/CustomLoading.vue';
export default {
    name: 'ReservationDetailPageLoader',
    components: {
        CustomLoading,
    },
};
</script>