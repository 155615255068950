<template>
  <v-row justify="center">
    <v-col
      cols="12"
      sm="8"
      md="8"
    >
      <v-card class="mx-auto">
        <div class="mx-auto text-center">
          <custom-loading type="createReservation" />
        </div>
      </v-card>
    </v-col>
    <v-col
      v-if="currentStep > 1"
      cols="12"
      sm="4"
      md="4"
    >
      <v-card class="mx-auto">
        <div class="mx-auto text-center">
          <custom-loading type="createReservationRightSideBar" />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CustomLoading from '@/shared/components/CustomLoading.vue';

export default {
    name: 'ReservationCreatePageLoader',
    components: {
        CustomLoading,
    },
    props: {
        currentStep: {
            type: Number,
            required: true,
        },
    },
};
</script>
