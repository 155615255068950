<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndDown ? '' : 'px-12'"
  >
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="3"
        lg="2"
        xl="2"
      >
        <v-card class="mx-auto">
          <v-card-title class="justify-center text-center">
            <div>Settings</div>
          </v-card-title>
          <v-card-text>
            <v-list dense>
              <template v-for="(item, i) in listItems">
                <v-list-group
                  v-if="(item.children || []).length"
                  :key="i"
                  :value="true"
                >
                  <template #activator>
                    <v-list-item-title>
                      <div class="text-capitalize">
                        {{ item.title }}
                      </div>
                    </v-list-item-title>
                  </template>
                  <v-list-item
                    v-for="(childItem, j) in item.children"
                    :key="j"
                    :to="childItem.path"
                    link
                  >
                    <v-list-item-title class="text-capitalize">
                      {{ childItem.title }}
                    </v-list-item-title>
                    <v-list-item-icon v-if="childItem.icon">
                      <v-icon small>
                        {{ childItem.icon }}
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-group>
                <v-list-item
                  v-else
                  :key="i"
                  :to="item.path"
                  link
                >
                  <v-list-item-title class="text-capitalize">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-icon v-if="item.icon">
                    <v-icon small>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="9"
        lg="10"
        xl="10"
      >
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { RoutesConfig } from '@/enums';
import { PERMISSIONS } from '@/enums';
import { mapState } from 'vuex';
export default {
    name: 'SettingsPage',
    components: {},
    data() {
        return {
            RoutesConfig,
        };
    },
    computed: {
        ...mapState(['user']),
        listItems() {
            const items = [
                {
                    title: 'Admin',
                    icon: '',
                    children: [
                        {
                            title: 'Roles',
                            path: RoutesConfig.settings.children.roles.path,
                            permission: PERMISSIONS.rolesView,
                        },
                        {
                            title: 'Permissions',
                            path: RoutesConfig.settings.children.permissions.path,
                            permission: PERMISSIONS.permissionsView,
                        },
                        {
                            title: 'Users',
                            path: RoutesConfig.settings.children.usersWithRoles.path,
                            permission: PERMISSIONS.userRolesView,
                        },
                    ],
                },
                {
                    title: 'Configurations',
                    path: RoutesConfig.settings.children.configurations.path,
                    permission: PERMISSIONS.configurationsView,
                    icon: '',
                },
            ];

            items.forEach((item) =>
                this.setSettingsPath(item.children ? item.children : item)
            );
            let list = items.map((item) => {
                if (item.children) {
                    let children = item.children.filter((i) =>
                        i.permission ? this.hasPermission(i.permission) : true
                    );
                    return {
                        ...item,
                        children,
                    };
                }
                if (item.permission)
                    if (this.hasPermission(item.permission)) {
                        return item;
                    }
            });

            list = list.filter((li) => (li.children ? li.children.length : true));
            return list;
        },
    },
    methods: {
        setSettingsPath(items = []) {
            if (!Array.isArray(items)) {
                items = [items];
            }
            items.forEach((item) => {
                if (item.path) {
                    item.path = `/settings/${item.path}`;
                }
            });
        },
    },
};
</script>
<style scoped>
.routerLink {
  text-decoration: none;
}
</style>
