<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    :nudge-right="0"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on }">
      <custom-field
        :id="id"
        v-model="inputVal"
        class="custom-date-picker-input"
        readonly
        rounded
        :required="required"
        prepend-inner-icon="mdi-calendar"
        v-bind="$attrs"
        clearable
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="inputVal"
      :range="range"
      :allowed-dates="allowedDates"
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import CustomField from './CustomField.vue';
export default {
    name: 'CustomDatePicker',
    components: {
        CustomField,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Array],
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        allowedDates: {
            type: Function,
            default: (val) => val,
        },
    },
    data() {
        return {
            isMenuOpen: false,
            date: '',
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        onInput() {
            if (!this.range || this.value.filter((i) => i).length > 0) {
                this.isMenuOpen = false;
                this.$emit('onDateSelect', this.value);
            }
        },
    },
};
</script>
<style lang="scss">
.custom-date-picker-input {
  .v-text-field--rounded>.v-input__control>.v-input__slot {
    padding: 0 12px !important;
  }
}
</style>
