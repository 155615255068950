<template>
  <v-app-bar
    :color="$vuetify.theme.dark ? '': 'white'" 
    flat
    dense
  >
    <v-row>
      <v-col>
        <div
          :class="$vuetify.theme.dark ? 'white--text': 'black--text'" 
          align="center"
        >
          © 2019 95 Star
          <a
            class="ml-3 ; text-decoration-none"
            @click="onClick('/privacy-policy')"
          >Privacy Policy</a>
          <a
            class="ml-3 ; text-decoration-none"
            @click="onClick('/terms-and-conditions')"
          >Terms and Conditions</a>
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
    name: 'AppFooter',
    methods:{
        onClick(path){
            if (this.$route.path !== path) this.$router.push(path).catch();
        }
    }
};
</script>
